import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Accessibility from 'accessibility-options';

import * as RegulationsService from '../../shared/services/regulations';
import { mobile } from '../../shared/styles/media-queries';
import { RegulationsWrapper, InfoPhrases } from '../../shared/components';
import AccessibilityBar from '../timblackfamilia/components/accessibility';
import Carousel from '../timblackfamilia/components/carousel';
import DependentLink from '../timblackfamilia/components/dependent-link';
import Hero from '../timblackfamilia/components/hero';
import Highlight from '../timblackfamilia/components/highlight';
import InfoWrapper from '../timblackfamilia/components/info-wrapper';
import SocialBox from '../timblackfamilia/components/social-box';
import Roaming from '../timblackfamilia/components/roaming';
import C6Info from '../timblackfamilia/components/c6-info';
import PortabilityBonus from '../timblackfamilia/components/portabilitybonus';
import * as ParamsService from '../../shared/services/params';
import { startAVI } from '../../shared/services/avi';
import Footer from '../../shared/components/footer';

const FamiliaPage = ({ pageContext: { content = {} } }) => {
  const pageName = 'familia-vendedor';
  const [selectedPlan, changeSelectedPlan] = useState(content.cards && content.cards[1]);
  const [pageVersion, changePageVersion] = useState('');

  useEffect(() => {
    const accessibility = new Accessibility();
    changePageVersion(ParamsService.get('v'));
    accessibility.init();
    startAVI();
  }, []);

  return (
    <Wrapper>
      <AccessibilityBar />

      <Hero plans={content.cards} selectedPlan={selectedPlan} changeSelectedPlan={changeSelectedPlan} pageVersion={pageVersion} />

      <C6Info selectedPlan={selectedPlan} />

      <PortabilityBonus/>

      {selectedPlan && <Highlight mainSva={selectedPlan.mainSva} />}

      <Carousel selectedPlan={selectedPlan} />

      <Roaming selectedPlan={selectedPlan} />

      <SocialBox />

      <InfoWrapper />

      <DependentLink />

      <InfoPhrases messages={content.footerMsg} />

      <RegulationsWrapper
        regulations={RegulationsService.get(content.regulations, content.summaries)}
        labelText="Faça download dos regulamentos e sumários"
        pageName={pageName}
      />

      <Footer template="-default" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;

  ${mobile} {
    padding: 0 0 60px 0;
  }
`;

export default FamiliaPage;
